import DOMPurify from "isomorphic-dompurify";

/**
 * Return purified content
 * All html tags removed except for <a />
 */

export const purify = (content: string) => {
  return (
    DOMPurify.sanitize(content, {
      ALLOWED_TAGS: ["a"],
    }) ?? ""
  );
};
