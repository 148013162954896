import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "now",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

/**
 * Given a date string, returns:
 *   - relative date format if it is within 3 days form now,
 *   - MMM D, YYYY format otherwise.
 * @param dateString Date string
 */
export const fromNowRecent = (dateString: string) => {
  const day = dayjs(dateString);
  if (dayjs().diff(day, "day") <= 3) {
    return day.fromNow();
  }
  return day.format("MMM D, YYYY");
};
