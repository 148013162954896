import React, { useEffect, useMemo, useState } from "react";
import MuiAvatar from "@material-ui/core/Avatar";
import styled from "styled-components";
import seedrandom from "seedrandom";

import { useTheme } from "lib/contexts/themeContext";
import { AsyncStatus } from "lib/enums";

interface AvatarProps {
  src?: string;
  letter?: string;
  className?: string;
}

/**
 * Displays avatar image.
 *   1. Use image `src`, if possible.
 *   2. Use `letter`, if `src` is not available (not given or not loadale.)
 *   3. Use default image, if none are available.
 */
const Avatar: React.FC<AvatarProps> = ({ src, className, letter }) => {
  const { palette } = useTheme();
  const [statusImage, setStatusImage] = useState(
    src ? AsyncStatus.Initial : AsyncStatus.Error,
  );
  /**
   * A random color: RGBA([0:190], [0:190] [0:190])
   */
  const color = useMemo(() => {
    if (src && statusImage === AsyncStatus.Error) {
      return (
        "#" +
        Math.floor(seedrandom(letter?.[0])() * Math.pow(191, 3))
          .toString(16)
          .padStart(6, "0")
      );
    }
    return palette.grey[100];
  }, [letter, palette.grey, src, statusImage]);

  useEffect(() => {
    if (src && statusImage === AsyncStatus.Initial) {
      const img = new Image();
      img.onload = () => {
        setStatusImage(AsyncStatus.Success);
      };
      img.onerror = () => {
        setStatusImage(AsyncStatus.Error);
      };
      setStatusImage(AsyncStatus.Requested);
      img.src = src;
    }
  }, [letter, src, statusImage]);

  useEffect(() => {
    if (src) {
      setStatusImage(AsyncStatus.Initial);
    }
  }, [src]);

  return (
    <div
      css={`
        width: 44px;
        height: 44px;
        border: 1px solid ${palette.grey[100]};
        border-radius: 50%;
        background-color: #f1f3f5;
      `}
      className={className}
    >
      {statusImage === AsyncStatus.Success ? (
        <StyledAvatar color={color} src={src} />
      ) : statusImage === AsyncStatus.Error ? (
        letter ? (
          <StyledAvatar color={color}>{letter}</StyledAvatar>
        ) : (
          <img
            css={`
              width: 100%;
              height: 100%;
              border-radius: 50%;
            `}
            src="/face.png"
            alt=""
          />
        )
      ) : null}
    </div>
  );
};

export default Avatar;

const StyledAvatar = styled(MuiAvatar)<{ color: string }>`
  && {
    width: 100%;
    height: 100%;
    font-weight: 700;
    background-color: ${(props) => props.color};
  }
`;
