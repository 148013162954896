import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const Spin: React.FC<{ className?: string }> = ({ className }) => (
  <CircularProgress className={className} />
);

/**
 * Displays centered spin on absolutely positioned overlay.
 */
export const SpinAbsolute: React.FC<{ className?: string }> = ({
  className,
  ...props
}) => {
  return (
    <SpinAbsoluteContainer className={className}>
      <Spin {...props} />
    </SpinAbsoluteContainer>
  );
};

/**
 * Displays centered spin on fixed overlay.
 */
export const SpinFixed: React.FC<{ className?: string }> = ({
  className,
  ...props
}) => {
  return (
    <SpinFixedContainer className={className}>
      <Spin {...props} />
    </SpinFixedContainer>
  );
};

const center = `
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
`;
const SpinAbsoluteContainer = styled.div`
  position: absolute;
  ${center}
`;
const SpinFixedContainer = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  padding-bottom: 10vh;
  ${center}
`;

export default Spin;
