import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "lib/contexts/themeContext";

/**
 * Custom hook for mobile resolution detection
 *
 * Mobile resolution is comprised of SMALL (small) configuration and down
 */
export default function useIsResMobile() {
  const theme = useTheme();
  const isResMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return isResMobile;
}
